
@import "../../shared/shared.scss";


.major-projects--bkg {
    position: relative;
}


.major-projects--frg {
    position: absolute;
    left: 0;
    top: 0;
}


.major-projects--project {
    position: absolute;
    display: none; //displayed with ViewMajorProjectsProject.js
    opacity: 0;
}


.major-projects--menu {
    position: absolute;
    bottom: 60px;
    left: 450px;
    width: 1500px;
    &:before {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 1050px;
        height: 12px;
        background: #b2b2b2;
        content: '';
    }
}


.major-projects--menu__list {
    position: absolute;
    display: block;
    left: 80px;
    top: -60px;
    li {
        display: inline-block;
        // margin: 0px 170px 0px 0px;
        width: 100px;
        text-align: center;
        margin-right: 120px;
        transform: translateX(-50%);
    }
}


.major-projects--btn {
    position: relative;
    padding: 5px;
    color: $primaryTextColour;
    font-size: 16px;
    //@include font-primary;
    font-family: 'korolev';
    img {
        position: absolute;
        left: 0;
        top: 0px;
    }
}


    .major-projects--btn__active {
        @extend .major-projects--btn;
        color: $highlightTextColour;
    }


.major-projects--dragger-progress {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 0px;
    height: 12px;
    background: $highlightTextColour;
}


.major-projects--dragger {
    position: absolute;
    top: -6px;
    left: 0px;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: $highlightTextColour;
	box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
	cursor: pointer;
	&:hover {
		background: $primaryTextColour;
	}
}


.major-projects--slide-note {
    position: absolute;
    left: -37px;
    top: -150px;
}