
@import '~sanitize.css'; //https://github.com/csstools/sanitize.css


/*$bkgColour: #002b47;*/

$bkgColour: #001738;
$frgColour: #021d2e;
$primaryTextColour: #FFF;
$highlightTextColour:#cea847;
$coreMenuZIndex: 10;
$enlargedZIndex: 20;
$coreMenuHeight: 100px;


@mixin font-primary {
	font-family: 'Gotham A', 'Gotham B', Verdana, Geneva, sans-serif;
  //font-family:"korolev";
  font-style: normal;
  font-weight: 400;
}

@mixin font-secondary {
  font-family:"korolev";
  font-style: normal;
  font-weight: 400;
}


// @mixin font-secondary {
// 	font-family: 'Times New Roman', Times, serif;
// 	font-weight: normal;
// 	font-style: normal;
// }


body {
  background: $frgColour;
  overflow-x: hidden;
}


button { 
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}


.shared--loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    margin: -50px 0px 0px -50px;
    content: "loading...";
    color: $primaryTextColour;
    text-align: center;
    @include font-primary();
    font-size: 20px;
  }
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: " ";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin: -6px 0px 0px -6px;
    color: #FFF;
    box-sizing: border-box;
    animation: animloader 1.5s linear infinite;
  }
}

  
@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 2px,  -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px,  38px 0 0 2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
  }
}


.shared--load-container {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  background: $bkgColour;
  opacity: 0;
}


.shared--img-fill {
  width: 100%;
  height: auto;
}


.fade-enter {
	opacity: 0;
}
.fade-enter-active {
	opacity: 1;
	transition: opacity 300ms;
}
.fade-exit {
	opacity: 1;
}
.fade-exit-active {
	opacity: 0;
	transition: opacity 300ms;
}