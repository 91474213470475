
@import "../../shared/shared.scss";


.aerial-map--transform-container {
    position: absolute;
    top: 100px;
    left: 0;
    width: #{( 1920 - 440 )}px;
    height: #{( 1080 - 100 )}px;
}

.aerial-map--bkg {
    position: relative;
}


.aerial-map--frg {
    position: absolute;
    left: 0;
    top: 0;
}


.aerial-map--menu {
    position: absolute;
    top: 0px;
    width: 430px;
    height: 1080px;
    right: -440px;
    background: $bkgColour;
    &:before {
        position: absolute;
        top: 0px;
        left: -10px;
        width: 12px;
        height: 100%;
        background: $highlightTextColour;
        content: ' '
    }
}


    .aerial-map--menu-options {
        position: absolute;
        top: 0px;
        right: 150px;
    }


.aerial-map--title {
    position: relative;
    display: block;
    margin: 20px 0px 30px 0px;
    color: $highlightTextColour;
    //font-size: 26px;
    //@include font-secondary;
    font-size: 32.5px;
    //font-family: 'Domaine Disp';
    font-family:'ivymode';
}


.aerial-map--btn {
    position: relative;
    display: block;
    margin: 0px 0px 30px 0px;
    padding: 5px 0px 0px 46px;
    color: $primaryTextColour;
    //font-size: 16px;
    font-size: 20px;
    //@include font-primary;
    //font-family:"ivymode";
    font-family: 'Domaine Disp';
    img {
        position: absolute;
        left: 0;
        top: 0px;
    }
}


    .aerial-map--btn__active {
        @extend .aerial-map--btn;
        color: $highlightTextColour;
    }


.aerial-map--zoom-tools {
    z-index: $coreMenuZIndex - 2;
    position: absolute;
    left: 50%;
    top: 850px;
    height: 40px;
    text-align: center;
    transform: translateX(-50%);

    button {
        margin: 0px 10px;
        cursor: pointer;
        &:hover {
            opacity: 0.9;
        }
    }
}

.aerial-map--zoom-tools--title {
    margin: 10px auto 10px;
    @include font-secondary;
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
}