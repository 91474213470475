
@import "../../shared/shared.scss";


.residences--rotations {
    position: absolute;
    top: 0;
    left: 0;
}

.residences--rotation-overlay {
    position: absolute;
    top: 0;
    left: 0;
}


.residences--button__rotate {
	position: absolute;
	top: 50%;
	margin-top: -30px;
	width: 61px;
	height: 60px;
	cursor: pointer;
    transition: all 0.3s ease-out;
	transform-origin: center center;
	&:focus {
		outline: none;
	}
}


	.residences--button__rotate-left {
		@extend .residences--button__rotate;
		right: 20px;
		&:hover {
			transform: rotate(40deg);
		}
	}


	.residences--button__rotate-right {
		@extend .residences--button__rotate;
		left: 20px;
		&:hover {
			transform: rotate(-40deg);
		}
	}


.residences--hotspots-container {
	position: absolute;
	left: 0px;
	top: 0px;
}


.residences--hotspot {
	position: absolute;
	width: 215px;
}


	.residences--hotspot__btn {
		z-index: 2;
		position: absolute;
		width: 100%;
		height: 40px;
		border-radius: 10px;
		padding: 10px 10px 8px 45px;
		color: $primaryTextColour;
		font-family:"korolev";
		font-size: 16px;
		background-color: $bkgColour;
		background-image: url(./icon-enlarge.png);
		background-repeat: no-repeat;
		background-position: 4px 4px;
		background-size: 30px 30px;
		text-align: left;
	}


		.residences--hotspot__btn-active {
			background-image: url(./icon-reduce.png);
		}


	.residences--hotspot__sub-menu {
		z-index: 1;
        display: none; //displayed with JS
        position: absolute;
        left: 0px;
        top: 20px;
        width: 100%;
        padding: 5px 0px 0px 0px;
        background: $bkgColour;
		border-radius: 0px 0px 10px 10px;
		ul {
			list-style: none;
			padding: 0px;
		}
		li {
			margin: 5px 0px;
			padding: 0px;
		}
	}
	

	.residences--hotspot__sub-menu-item {
		display: block;
		width: 100%;
		margin: 0px;
		padding: 5px 0px 5px 45px;
		color: $primaryTextColour;
		font-size: medium;
		text-align: left;
		transition: all 0.2s ease-out;
		&:hover {
			opacity: 0.7;
		}
	}


.residences--level-group {
	z-index: $coreMenuZIndex + 1;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $frgColour;
}


	.residences--level-group__close-btn {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 60px;
		height: 60px;
		background: url(./btn--close.png) no-repeat center center;
		background-size: 40px 40px;
		transition: all 0.3s ease-out;
		&:hover {
			opacity: 0.5;
		}
	}


.residences--apartment-btns {
	position: absolute;
	left: 0px;
	top: 0px;
}


	.residences--apartment-btn {
		position: absolute;
		width: 40px;
		height: 40px;
		background: url(./icon-enlarge_apartment.png) no-repeat;
		background-size: 40px 40px;
	}


.residences--apartment {
	z-index: $coreMenuZIndex + 2;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $frgColour;
}


	.residences--apartment__views {
		position: absolute;
		left: 0px;
		bottom: -160px;
		width: 100%;
		height: 200px;
		background: $bkgColour;
	}


		.residences--apartment__views-btn {
			position: absolute;
			width: 100%;
			height: 40px;
			padding: 10px;
			text-align: center;
			color: $primaryTextColour;
			background: $bkgColour;
			font-family:"korolev";
			font-weight: medium;
			font-size: 16px;
		}


		.residences--apartment__views-tmbs {
			position: absolute;
			width: 100%;
			left: 10px;
			top: 50px;
			list-style-type: none;
		}


		.residences--apartment__views-tmb {
			display: inline-block;
			width: 150px;
			margin: 0px 20px 25px 0px;
			text-align: left;
			transition: all 0.3s ease-out;
			h2 {
				margin: 0px 0px 5px 0px;
				padding: 0px;
				color: $primaryTextColour;
				@include font-primary;
				font-size: 14px;
				text-transform: uppercase;
				text-align: left;
			}
		}


	.residences--apartment__close-btn {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 60px;
		height: 60px;
		background: url(./btn--close.png) no-repeat center center;
		background-size: 40px 40px;
		transition: all 0.3s ease-out;
		&:hover {
			opacity: 0.5;
		}
	}